import React, { Fragment } from 'react';
// import SectionHeading from '../common/SectionHeading';
import Label from '../common/Label';
import classes from './Section3.module.css';
const Section3 = () => {
  return (
    <Fragment>
      <div className={classes.heading} id='about'>
        <div className={classes.top_divider} />
        <Label text='get to know me' gridCol='1/6' />
        <Label text='about' gridCol='6/7' />
        <p className={classes.grey_label}>2023-2024</p>
      </div>
      <section className={classes.about}>
        {/* wrapped this in a div to be able to change css on media queries */}
        <div className={classes.label_container}>
          <Label text='03' />
        </div>
        {/*/////////////////////////////////////////////////////////////// 
        
        FULL STACK
SOFTWARE DEV
WEBFLOW GURU*/}
        <div className={classes.about_avatar}></div>
        <div className={classes.heading_wrapper}>
          <h2 className={classes.about_h2}>driven developer </h2>
          <h2 className={classes.about_h2}>mastering optimization</h2>
          <h2 className={classes.about_h2}>& innovation</h2>
        </div>
        <p className={classes.about_subheading}>
          Transforming complex challenges into optimized, scalable solutions.
        </p>
        {/* text block part */}
        <div className={classes.about_text_block_container}>
          <div className={classes.about_text_block}>
            <Label text='01' />
            <div className={classes.about_text_section}>
              <h3 className={classes.about_h3}>Uncompromising Standards</h3>
              <p className={classes.about_text}>
                In every endeavor, I maintain a non-negotiable commitment to
                excellence — for myself and those I collaborate with. My high
                standards act as a catalyst for team performance, pushing
                projects from mere completion to exceptional delivery.
              </p>
            </div>
          </div>
          <div className={classes.about_text_block}>
            <Label text='02' />
            <div className={classes.about_text_section}>
              <h3 className={classes.about_h3}>Team-Oriented</h3>
              <p className={classes.about_text}>
                Drawing inspiration from military principles of unity and
                strategy, I bring a disciplined approach to teamwork. Clear
                communication, tactical collaboration, and an unflinching focus
                on the mission objective make me a valued asset in any team
                setting.
              </p>
            </div>
          </div>

          <div className={classes.about_text_block}>
            <Label text='03' />
            <div className={classes.about_text_section}>
              <h3 className={classes.about_h3}>Technical Excellence</h3>
              <p className={classes.about_text}>
                My coding skills in C++, JavaScript, Swift and Python are backed
                by a relentless commitment to technical excellence. I'm
                continually exploring newer technologies, frameworks, and
                libraries to ensure the solutions I deliver are not just
                functional but optimized for performance.
              </p>
            </div>
          </div>
        </div>
        {/* services */}
        <h3 className={classes.about_h3__services}>services</h3>
        <div className={classes.services_container}>
          <div className={classes.services_content_container}>
            <Label text='01' />
            <div className={classes.services_title_container}>
              <strong className={classes.service_title}>
                Front-End Development
              </strong>
              <p className={classes.services_description}>
                Crafting intuitive user interfaces with optimized React
                components.
              </p>
            </div>
            <div className={classes.bottom_divider}></div>
          </div>
          <div className={classes.services_content_container}>
            <Label text='02' />
            <div className={classes.services_title_container}>
              <strong className={classes.service_title}>
                Back-End Development
              </strong>
              <p className={classes.services_description}>
                Robust back-ends built on Node.js and MongoDB.
              </p>
            </div>
            <div className={classes.bottom_divider}></div>
          </div>
          <div className={classes.services_content_container}>
            <Label text='03' />
            <div className={classes.services_title_container}>
              <strong className={classes.service_title}>
                High-Performance Computing
              </strong>
              <p className={classes.services_description}>
                Optimizing algorithms for maximum efficiency
              </p>
            </div>
            <div className={classes.bottom_divider}></div>
          </div>
          <div className={classes.services_content_container}>
            <Label text='04' />
            <div className={classes.services_title_container}>
              <strong className={classes.service_title}>
                Security-Conscious Development
              </strong>
              <p className={classes.services_description}>
                Writing code with an eye toward best practices in cybersecurity
              </p>
            </div>
            <div className={classes.bottom_divider}></div>
          </div>
        </div>

        {/* numbers */}
        <h3 className={classes.about_h3__numbers}>numbers</h3>
        <div className={classes.numbers_container}>
          <div className={classes.number_block}>
            <div className={classes.top_divider} />
            <div className={classes.number_wrapper}>
              <div className={classes.number}>4</div>
              <p className={classes.number_description}>
                core programming languages
              </p>
            </div>
          </div>
          <div className={classes.number_block}>
            <div className={classes.top_divider} />
            <div className={classes.number_wrapper}>
              <div className={classes.number}>2</div>
              <p className={classes.number_description}>languages spoken</p>
            </div>
          </div>
          <div className={classes.number_block}>
            <div className={classes.top_divider} />
            <div className={classes.number_wrapper}>
              <div className={classes.number}>3</div>
              <p className={classes.number_description}>
                operating systems proficient
              </p>
            </div>
          </div>
          <div className={classes.number_block}>
            <div className={classes.top_divider} />
            <div className={classes.number_wrapper}>
              <div className={classes.number}>500+</div>
              <p className={classes.number_description}>Leetcodes solved</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Section3;
