import React from 'react';
import classes from './Footer.module.css';
import Label from '../common/Label';

import contactImg from '../../assets/portfolio_contact.png';
const Footer = () => {
  return (
    <footer className={classes.footer} id='contact'>
      <div className={classes.heading}>
        <div className={classes.top_divider} />
        <Label text='04' gridCol='1/2' gridRow='1/1' />
        <Label text='jndev.io ©' gridCol='6/6' gridRow='1/1' />
        <Label text='full stack developer' gridCol='9/9' gridRow='1/1' />
      </div>
      {/*  */}
      <div className={classes.img_container}>
        <img
          src={contactImg}
          alt='contact.png'
          className={classes.contact_image}
        />
      </div>
      <div className={classes.form_container}>
        <form name='form' netlify className={classes.form}>
          <input
            type='text'
            placeholder='Name'
            id='name'
            className={classes.contact_input}
            required
          />
          <input
            type='email'
            placeholder='Email'
            className={classes.contact_input}
            required
            id='email'
          />
          <textarea
            type='text'
            placeholder='Your message...'
            className={classes.contact_textarea}
            required
            id='message'
          />
          <button type='submit' className={classes.contact_btn}>
            submit
          </button>
        </form>
      </div>
      <div className={classes.contact_details} id='about'>
        <div className={classes.top_divider}></div>
        <Label text='contact info' gridCol='1/2' />
        <Label text='direct' gridCol='6/7' />
        <p className={classes.grey_label}>socials</p>
        <ul className={classes.footer_direct_links}>
          <li>
            <a className={classes.footer_link} href='https://google.com'>
              j@jndev.io
            </a>
          </li>
          {/* <li>
            <a className={classes.footer_link} href='https://google.com'>
              +34 666-852-870
            </a>
          </li> */}
        </ul>
        <ul className={classes.footer_social_links}>
          <li>
            <a
              className={classes.footer_link}
              href='https://google.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              LINKEDIN
            </a>
          </li>
          <li>
            <a
              className={classes.footer_link}
              href='https://leetcode.com/jdnev/'
              target='_blank'
              rel='noopener noreferrer'
            >
              LEETCODE
            </a>
          </li>
          <li>
            <a
              className={classes.footer_link}
              href='https://github.com/jakobpl'
              target='_blank'
              rel='noopener noreferrer'
            >
              GITHUB
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
